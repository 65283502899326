<template>
  <footer class="text-white py-4">
    <div class="container">
      <div class="row">
        <!-- Contact details on the left -->
        <div class="col-md-6 text-md-start text-center mb-4 mb-md-0">
          <div class="contact-info">
<!--            <p>-->
<!--              <i class="fas fa-map-marker-alt"></i> Neustr. 64, 41836 Hückelhoven-->
<!--            </p>-->
            <p>
              <i class="fas fa-envelope"></i>
              <a href="mailto:info@crafting-systems.de" class="custom-link">
                info@crafting-systems.de
              </a>
            </p>
            <p>
              <i class="fas fa-phone"></i>
              <a href="tel:+4915162987881" class="custom-link">
                +49 151 629 878 81
              </a>
            </p>
          </div>
        </div>

        <!-- Impressum and Datenschutz on the right, aligned at the top -->
        <div class="col-md-6 text-md-end text-center">
          <div class="legal-info">
            <p class="legal-item">
              <router-link to="/impressum" class="custom-link">Impressum</router-link>
              <i class="fas fa-gavel"></i>
            </p>
            <p class="legal-item">
              <router-link to="/datenschutz" class="custom-link">Datenschutz</router-link>
              <i class="fas fa-shield-alt"></i>
            </p>
            <p class="legal-item">
              <router-link to="/contact" class="custom-link">Kontakt</router-link>
              <i class="fas fa-envelope"></i>
            </p>
          </div>
        </div>
      </div>

      <!-- White divider line -->
      <hr class="divider-line">

      <!-- Bottom section: Copyright and Social Icons -->
      <div class="row align-items-center pt-3">
        <!-- Copyright on the left -->
        <div class="col-md-6 text-md-start text-center mb-2 mb-md-0">
          <p class="mb-0">&copy; 2024 Crafting Systems GmbH. Alle Rechte vorbehalten.</p>
        </div>

        <!-- Social media icons on the right -->
        <div class="col-md-6 text-md-end text-center">
          <div class="social-icons">
            <a href="https://www.linkedin.com/company/100260640/" target="_blank" class="social-link">
              <i class="fab fa-linkedin"></i>
            </a>
            <a href="https://github.com/Crafting-Systems" target="_blank" class="social-link">
              <i class="fab fa-github"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
export default {
  name: 'SiteFooter',
};
</script>

<style scoped>
footer {
  background-color: #2c2b2b; /* Dark Gray for Footer Background */
  color: #f6f8f9; /* Light Gray for Text */
}

footer p {
  color: #f6f8f9;
  font-size: 1rem;
}

footer p i {
  color: #e2a84b;
  margin-right: 8px;
}

.custom-link {
  color: #f6f8f9;
  text-decoration: none;
  transition: color 0.3s ease;
}

.custom-link:hover {
  color: #c75b29;
}

.divider-line {
  border-top: 1px solid #f6f8f9;
  margin: 20px 0;
  opacity: 0.2;
}

/* Contact and Legal Info Styling */
.contact-info p,
.legal-info p {
  font-size: 1.1rem;
}

.legal-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.legal-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.legal-item i {
  color: #e2a84b;
  margin-left: 8px;
}

.contact-info p,
.legal-info p,
.social-icons a {
  margin-bottom: 10px;
}

/* Social Icons Styling */
.social-icons {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.social-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #3a3a3a;
  transition: background-color 0.3s ease;
}

.social-link i {
  font-size: 1.5rem;
  color: #e2a84b;
}

.social-link:hover {
  background-color: #c75b29;
}

@media (max-width: 768px) {
  footer .col-md-6 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .legal-info {
    align-items: center;
  }

  .social-icons {
    justify-content: center;
  }
}
</style>
