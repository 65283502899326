import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "team",
  class: "py-5",
  style: {"background-color":"#2c2b2b"}
}
const _hoisted_2 = { class: "container text-center" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "card shadow-sm h-100 hover-card modern-card" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = { class: "card-body d-flex flex-column align-items-center" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "card-text" }
const _hoisted_9 = { class: "card-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", {
        class: "mb-5",
        style: {"color":"#f6f8f9"}
      }, "Unser Team", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.team, (member) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["col-md-4 mb-4 team-card-slide-in", { 'team-card-slide-in-active': _ctx.teamCardsVisible }]),
            key: member.id
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                src: member.photo,
                alt: member.name,
                class: "card-img-top rounded-circle"
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("h5", _hoisted_7, _toDisplayString(member.name), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(member.role), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(member.description), 1)
              ])
            ])
          ], 2))
        }), 128))
      ])
    ])
  ]))
}