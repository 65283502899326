import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "services",
  class: "py-5"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "card shadow-sm h-100" }
const _hoisted_5 = { class: "card-body d-flex flex-column" }
const _hoisted_6 = { class: "d-flex align-items-start mb-3" }
const _hoisted_7 = { class: "icon-container d-flex justify-content-center align-items-center" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "card-text flex-grow-1" }
const _hoisted_10 = { class: "list-unstyled mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "intro-text text-center mb-5" }, [
      _createElementVNode("h2", null, "Unsere Schwerpunkte und Lösungen"),
      _createElementVNode("p", null, "Wir kombinieren modernste Technologien mit maßgeschneiderten Dienstleistungen, um Ihre Herausforderungen zu lösen.")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.themes, (theme) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["col-md-6 col-lg-3 mb-4 card-slide-in", { 'card-slide-in-active': _ctx.cardsVisible }]),
            key: theme.title
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("i", {
                      class: _normalizeClass([theme.icon, "theme-icon"])
                    }, null, 2)
                  ])
                ]),
                _createElementVNode("h3", _hoisted_8, _toDisplayString(theme.title), 1),
                _createElementVNode("p", _hoisted_9, _toDisplayString(theme.description), 1),
                _cache[1] || (_cache[1] = _createElementVNode("div", { class: "services-header mt-3" }, [
                  _createElementVNode("h4", { class: "mb-0" }, "Ihre Möglichkeiten")
                ], -1)),
                _createElementVNode("ul", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(theme.services, (service) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: service.name,
                      class: "d-flex align-items-center"
                    }, [
                      _createElementVNode("i", {
                        class: _normalizeClass([service.icon, "service-icon mr-2"])
                      }, null, 2),
                      _createElementVNode("span", null, _toDisplayString(service.name), 1)
                    ]))
                  }), 128))
                ]),
                _createVNode(_component_router_link, {
                  to: theme.route,
                  class: "btn btn-outline-primary mt-3"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode(" Weitere Informationen "),
                    _createElementVNode("i", { class: "fas fa-arrow-right" }, null, -1)
                  ])),
                  _: 2
                }, 1032, ["to"])
              ])
            ])
          ], 2))
        }), 128))
      ])
    ])
  ]))
}