import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import 'bootstrap';  // Import Bootstrap JS for components like modals
import '@fortawesome/fontawesome-free/css/all.css'; // Font Awesome CSS
import router from './router'; // Importiere Vue Router

// Erstelle die App und verwende den Router
createApp(App)
    .use(router) // Router verwenden
    .mount('#app'); // App an das HTML-Element mit ID 'app' binden
