import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "partners",
  class: "py-5"
}
const _hoisted_2 = { class: "container text-center" }
const _hoisted_3 = { class: "row justify-content-center" }
const _hoisted_4 = { class: "card shadow-sm h-100 d-flex align-items-center justify-content-center" }
const _hoisted_5 = { class: "card-body d-flex flex-column align-items-center justify-content-center text-center" }
const _hoisted_6 = ["src", "alt"]
const _hoisted_7 = { class: "card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "mb-5" }, "Unsere Partner", -1)),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partners, (partner) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["col-md-4 mb-4 partner-card-slide-in", { 'partner-card-slide-in-active': _ctx.partnerCardsVisible }]),
            key: partner.id
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("img", {
                  src: partner.logo,
                  alt: `Logo von ${partner.name}`,
                  class: "img-fluid partner-logo mb-3"
                }, null, 8, _hoisted_6),
                _createElementVNode("h5", _hoisted_7, _toDisplayString(partner.name), 1)
              ])
            ])
          ], 2))
        }), 128))
      ])
    ])
  ]))
}