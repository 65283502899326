import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/components/HomePage.vue';
import SiteImpressum from '@/components/SiteImpressum.vue';
import SiteDatenschutz from '@/components/SiteDatenschutz.vue';
import ContactForm from '@/components/ContactForm.vue';
import ContentDatamanagement from "@/components/ContentDatamanagement.vue";
import ContentKI from "@/components/ContentKI.vue";
import ContentLLM from "@/components/ContentLLM.vue";
import contentIoT from "@/components/ContentIoT.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomePage,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: ContactForm,
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: SiteImpressum,
    },
    {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: SiteDatenschutz,
    },
    {
        path: '/datenmanagement',
        name: 'Datenmanagement',
        component: ContentDatamanagement,
    },
    {
        path: '/ki',
        name: 'KI',
        component: ContentKI,
    },
    {
        path: '/llm',
        name: 'LLM',
        component: ContentLLM,
    },
    {
        path: '/iot',
        name: 'IoT',
        component: contentIoT,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth', // Sanftes Scrollen
            };
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

export default router;