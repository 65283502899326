<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top">
    <div class="container">
      <img src="@/assets/cs-logo.svg" width="90" alt="CS logo">
      <router-link class="navbar-brand" :to="{ path: '/' }">Crafting Systems GmbH</router-link>

      <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="{ path: '/', hash: '#about' }"
                :class="activeClass('about')"
            >
              <i class="fas fa-info-circle"></i> Über Uns
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="{ path: '/', hash: '#services' }"
                :class="activeClass('services')"
            >
              <i class="fas fa-tools"></i> Lösungen
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="{ path: '/', hash: '#team' }"
                :class="activeClass('team')"
            >
              <i class="fas fa-users"></i> Team
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
                class="nav-link"
                :to="{ path: '/', hash: '#partners' }"
                :class="activeClass('partners')"
            >
              <i class="fas fa-handshake"></i> Partner
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount, watch, computed, nextTick } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'SiteNavbar',
  setup() {
    const activeSection = ref('');
    const route = useRoute();
    let observer: IntersectionObserver | null = null;

    // Computed property to dynamically apply 'active-link' class
    const activeClass = computed(() => (section: string) => activeSection.value === section ? 'active-link' : '');

    // Observer initialization to track active section
    const initializeObserver = () => {
      if (observer) {
        observer.disconnect();
      }

      observer = new IntersectionObserver(
          (entries) => {
            const visibleEntry = entries.find((entry) => entry.isIntersecting);
            if (visibleEntry) {
              activeSection.value = visibleEntry.target.id;
            }
          },
          { threshold: 0.7 }
      );

      const sections = ['about', 'services', 'team', 'partners'];
      sections.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          observer?.observe(sectionElement);
        }
      });
    };

    // Function to update active section based on scroll position
    const setActiveSectionOnScroll = () => {
      const sections = ['about', 'services', 'team', 'partners'];
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
            activeSection.value = section;
            break;
          }
        }
      }
    };

    onMounted(async () => {
      await nextTick();
      activeSection.value = route.hash ? route.hash.replace('#', '') : 'about'; // Set initial section
      initializeObserver(); // Initialize IntersectionObserver
      window.addEventListener('scroll', setActiveSectionOnScroll); // Add scroll listener
    });

    onBeforeUnmount(() => {
      observer?.disconnect();
      window.removeEventListener('scroll', setActiveSectionOnScroll); // Remove scroll listener on unmount
    });

    // Watch for route changes to reset active section without reinitializing observer
    watch(
        () => route.fullPath,
        (newPath) => {
          if (newPath.startsWith('/#') || newPath === '/') {
            activeSection.value = route.hash ? route.hash.replace('#', '') : 'about'; // Reset active section
          } else {
            activeSection.value = ''; // Clear active section if on another route
          }
        }
    );

    return { activeClass };
  },
});
</script>

<style scoped lang="less">

@import '@/assets/style.less';
/* Navbar Styles */
.navbar {
  background-color: @background-color;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  z-index: 1000;
}

.navbar-brand {
  color: @text-color;
  font-weight: bold;
  text-decoration: none;
  font-size: 2em;
  margin-left: 10px;
}

.navbar-brand:hover {
  color: @secondary-color !important;
}

/* Navbar Links */
.nav-link {
  color: #f6f8f9 !important;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.nav-link i {
  margin-right: 8px;
  color: #e2a84b;
}

.nav-link:hover {
  color: @secondary-color !important;
  background-color: rgba(231, 175, 89, 0.1);
  //border-radius: 10px;
}

/* Active link styling */
.active-link {
  color: #c75b29 !important;
  font-weight: bold;
  //border-bottom: 2px solid #c75b29;
  //border-radius: 10px;
}

/* Mobile Navbar */
.navbar-toggler {
  border: none;
  outline: none;
}

.navbar-toggler-icon {
  color: #e2a84b;
}

/* Adjustments for mobile view */
@media (max-width: 992px) {
  .navbar-nav {
    text-align: center;
    padding: 1rem 0;
  }

  .nav-item {
    margin-bottom: 0.5rem;
  }

  .navbar {
    padding: 0.8rem 1rem;
  }
}
</style>
