<template>
  <div>
    <!-- About Us Abschnitt -->
    <section id="about">
      <CompanyAboutUs />
    </section>

    <!-- Services Abschnitt -->
    <section id="services">
      <CompanyServices />
    </section>

    <!-- Team Abschnitt -->
    <section id="team">
      <CompanyTeam />
    </section>

    <!-- Partners Abschnitt -->
    <section id="partners">
      <CompanyPartners />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import CompanyAboutUs from './CompanyAboutUs.vue';
import CompanyServices from './CompanyServices.vue';
import CompanyTeam from './CompanyTeam.vue';
import CompanyPartners from './CompanyPartners.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    CompanyAboutUs,
    CompanyServices,
    CompanyTeam,
    CompanyPartners,
  },
  setup() {
    onMounted(() => {
      // Setze den Hash auf #about, falls keiner vorhanden ist
      if (!window.location.hash) {
        window.location.hash = '#about';
      }
    });
  },
});
</script>
