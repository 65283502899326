import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/datamanagement/data.jpg'
import _imports_1 from '@/assets/datamanagement/data2.jpg'


const _hoisted_1 = { class: "data-management-page" }
const _hoisted_2 = { class: "section motivation-explanation-section py-5 dark-section" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row align-items-center" }
const _hoisted_5 = { class: "col-md-6 text-center" }
const _hoisted_6 = { class: "section solution-section py-5 dark-section" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "row align-items-center" }
const _hoisted_9 = { class: "col-md-6 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-in", { 'text-slide-in-active': _ctx.textVisible }])
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "highlight-text highlight-text-margin" }, "Datenmanagement", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "lead-light mb-4" }, " Effizientes Datenmanagement ist der Schlüssel zu einem zukunftssicheren Unternehmenserfolg. Es bringt Struktur in Ihre Daten, gewährleistet höchste Qualität und macht Informationen jederzeit zugänglich. Nutzen Sie Ihre Daten als wertvolle Ressource, um smarte Entscheidungen zu treffen, Prozesse zu optimieren und Ihre Kosten nachhaltig zu senken. Setzen Sie auf klare Datenstrukturen und erschließen Sie das volle Potenzial Ihres Unternehmens! ", -1)),
            _createVNode(_component_router_link, {
              to: "/contact",
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Kontaktieren Sie uns "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Motivation für Datenmanagement",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm top-image", { 'slide-in-active': _ctx.imageVisible }])
            }, null, 2)
          ])
        ])
      ])
    ]),
    _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"section solution-overview-section py-5 light-section\" data-v-7b6c8386><div class=\"container\" data-v-7b6c8386><div class=\"row text-center mb-5\" data-v-7b6c8386><div class=\"col-md-4\" data-v-7b6c8386><i class=\"fas fa-folder-open fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-7b6c8386></i><h4 class=\"highlight-text-dark\" data-v-7b6c8386>Datenkataloge erstellen</h4><p class=\"lead-dark\" data-v-7b6c8386> Lassen Sie umfassende Datenkataloge erstellen, um Ihre Informationen schnell und strukturiert auffindbar zu machen. </p></div><div class=\"col-md-4\" data-v-7b6c8386><i class=\"fas fa-check-circle fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-7b6c8386></i><h4 class=\"highlight-text-dark\" data-v-7b6c8386>Datenqualität sichern</h4><p class=\"lead-dark\" data-v-7b6c8386> Wir optimieren und sichern Ihre Datenqualität für eine zuverlässige Basis in der Analyse und Entscheidungsfindung. </p></div><div class=\"col-md-4\" data-v-7b6c8386><i class=\"fas fa-search fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-7b6c8386></i><h4 class=\"highlight-text-dark\" data-v-7b6c8386>Datenverfügbarkeit steigern</h4><p class=\"lead-dark\" data-v-7b6c8386> Erhöhen Sie die Verfügbarkeit Ihrer Daten im gesamten Unternehmen, um eine schnelle Time-to-Analytics zu gewährleisten. </p></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Unsere Datenmanagement-Lösung",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm solution-image", { 'slide-in-active': _ctx.solutionImageVisible }])
            }, null, 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-up", { 'text-slide-up-active': _ctx.solutionTextVisible }])
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "highlight-text" }, "Unsere Lösung", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lead-light mb-4" }, " Unsere maßgeschneiderte Datenmanagement-Lösung stellt sicher, dass Ihre Daten sicher, nutzbar und leicht zugänglich sind – für ein dynamisches und datengetriebenes Unternehmen. ", -1)),
            _createVNode(_component_router_link, {
              to: "/contact",
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Jetzt Kontakt aufnehmen "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"section benefits-section py-5 light-section\" data-v-7b6c8386><div class=\"container\" data-v-7b6c8386><div class=\"row text-center\" data-v-7b6c8386><div class=\"col-md-12\" data-v-7b6c8386><h2 class=\"highlight-text-dark\" data-v-7b6c8386>Ihre Vorteile</h2><p class=\"lead-dark mb-4\" data-v-7b6c8386> Mit unserem auf Ihr Unternehmen zugeschnittenen Datenmanagement erzielen Sie eine höhere Datenverfügbarkeit, verkürzen Ihre Time-to-Analytics und ermöglichen fundierte Entscheidungen. </p></div></div><div class=\"row text-center mt-4\" data-v-7b6c8386><div class=\"col-md-4\" data-v-7b6c8386><i class=\"fas fa-layer-group fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-7b6c8386></i><h4 class=\"highlight-text-dark\" data-v-7b6c8386>Datenintegration</h4><p class=\"lead-dark\" data-v-7b6c8386>Nahtlose Integration aus verschiedenen Quellen schafft ein umfassendes Datenbild und verbessert die Entscheidungsqualität.</p></div><div class=\"col-md-4\" data-v-7b6c8386><i class=\"fas fa-chart-line fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-7b6c8386></i><h4 class=\"highlight-text-dark\" data-v-7b6c8386>Optimierte Entscheidungsfindung</h4><p class=\"lead-dark\" data-v-7b6c8386>Zuverlässige und gut zugängliche Daten schaffen die Basis für schnellere, fundierte Entscheidungen und minimieren Risiken in Ihrem Unternehmen.</p></div><div class=\"col-md-4\" data-v-7b6c8386><i class=\"fas fa-clock fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-7b6c8386></i><h4 class=\"highlight-text-dark\" data-v-7b6c8386>Verkürzte Time-to-Analytics</h4><p class=\"lead-dark\" data-v-7b6c8386>Eine schnelle Datenfindung und Analyse bieten schneller Zugang zu Erkenntnissen und ermöglichen Ihrem Unternehmen eine rasche Reaktion.</p></div></div></div></div>", 1))
  ]))
}