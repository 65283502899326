import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "about" }
const _hoisted_2 = { id: "services" }
const _hoisted_3 = { id: "team" }
const _hoisted_4 = { id: "partners" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyAboutUs = _resolveComponent("CompanyAboutUs")!
  const _component_CompanyServices = _resolveComponent("CompanyServices")!
  const _component_CompanyTeam = _resolveComponent("CompanyTeam")!
  const _component_CompanyPartners = _resolveComponent("CompanyPartners")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("section", _hoisted_1, [
      _createVNode(_component_CompanyAboutUs)
    ]),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_CompanyServices)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_CompanyTeam)
    ]),
    _createElementVNode("section", _hoisted_4, [
      _createVNode(_component_CompanyPartners)
    ])
  ]))
}