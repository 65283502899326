<template>
  <section class="data-management-page">

    <!-- Abschnitt 1: Motivation und Erklärung für Datenmanagement -->
    <div class="section motivation-explanation-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 mb-4 mb-md-0 text-slide-in" :class="{ 'text-slide-in-active': textVisible }">
            <h2 class="highlight-text highlight-text-margin">Datenmanagement</h2>
            <p class="lead-light mb-4">
              Effizientes Datenmanagement ist der Schlüssel zu einem zukunftssicheren Unternehmenserfolg. Es bringt Struktur in Ihre Daten, gewährleistet höchste Qualität und macht Informationen jederzeit zugänglich. Nutzen Sie Ihre Daten als wertvolle Ressource, um smarte Entscheidungen zu treffen, Prozesse zu optimieren und Ihre Kosten nachhaltig zu senken. Setzen Sie auf klare Datenstrukturen und erschließen Sie das volle Potenzial Ihres Unternehmens!
            </p>
            <router-link to="/contact" class="btn btn-primary btn-lg mt-2">
              Kontaktieren Sie uns
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
          <div class="col-md-6 text-center">
            <img
                src="@/assets/datamanagement/data.jpg"
                alt="Motivation für Datenmanagement"
                class="img-fluid rounded-img shadow-sm top-image"
                :class="{ 'slide-in-active': imageVisible }"
            />
          </div>
        </div>

      </div>
    </div>

    <!-- Abschnitt 2: Unsere Lösung im Überblick -->
    <div class="section solution-overview-section py-5 light-section">
      <div class="container">
        <div class="row text-center mb-5">
          <div class="col-md-4">
            <i class="fas fa-folder-open fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Datenkataloge erstellen</h4>
            <p class="lead-dark">
              Lassen Sie umfassende Datenkataloge erstellen, um Ihre Informationen schnell und strukturiert auffindbar zu machen.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-check-circle fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Datenqualität sichern</h4>
            <p class="lead-dark">
              Wir optimieren und sichern Ihre Datenqualität für eine zuverlässige Basis in der Analyse und Entscheidungsfindung.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-search fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Datenverfügbarkeit steigern</h4>
            <p class="lead-dark">
              Erhöhen Sie die Verfügbarkeit Ihrer Daten im gesamten Unternehmen, um eine schnelle Time-to-Analytics zu gewährleisten.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 3: Detaillierte Lösung -->
    <div class="section solution-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center">
            <img
                src="@/assets/datamanagement/data2.jpg"
                alt="Unsere Datenmanagement-Lösung"
                class="img-fluid rounded-img shadow-sm solution-image"
                :class="{ 'slide-in-active': solutionImageVisible }"
            />
          </div>
          <div class="col-md-6 mb-4 mb-md-0 text-slide-up" :class="{ 'text-slide-up-active': solutionTextVisible }">
            <h2 class="highlight-text">Unsere Lösung</h2>
            <p class="lead-light mb-4">
              Unsere maßgeschneiderte Datenmanagement-Lösung stellt sicher, dass Ihre Daten sicher, nutzbar und leicht zugänglich sind – für ein dynamisches und datengetriebenes Unternehmen.
            </p>
            <router-link to="/contact" class="btn btn-primary btn-lg mt-2">
              Jetzt Kontakt aufnehmen
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 4: Vorteile für Ihr Unternehmen -->
    <div class="section benefits-section py-5 light-section">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12">
            <h2 class="highlight-text-dark">Ihre Vorteile</h2>
            <p class="lead-dark mb-4">
              Mit unserem auf Ihr Unternehmen zugeschnittenen Datenmanagement erzielen Sie eine höhere Datenverfügbarkeit, verkürzen Ihre Time-to-Analytics und ermöglichen fundierte Entscheidungen.
            </p>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-md-4">
            <i class="fas fa-layer-group fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Datenintegration</h4>
            <p class="lead-dark">Nahtlose Integration aus verschiedenen Quellen schafft ein umfassendes Datenbild und verbessert die Entscheidungsqualität.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-chart-line fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Optimierte Entscheidungsfindung</h4>
            <p class="lead-dark">Zuverlässige und gut zugängliche Daten schaffen die Basis für schnellere, fundierte Entscheidungen und minimieren Risiken in Ihrem Unternehmen.</p>
          </div>

          <div class="col-md-4">
            <i class="fas fa-clock fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Verkürzte Time-to-Analytics</h4>
            <p class="lead-dark">Eine schnelle Datenfindung und Analyse bieten schneller Zugang zu Erkenntnissen und ermöglichen Ihrem Unternehmen eine rasche Reaktion.</p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'DataManagementPage',
  setup() {
    const textVisible = ref(false);
    const imageVisible = ref(false);
    const solutionTextVisible = ref(false);
    const solutionImageVisible = ref(false);

    onMounted(() => {
      const observerOptions = {
        root: null,
        threshold: 0.1,
      };

      const observerCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains('top-image')) {
              imageVisible.value = true;
            } else if (entry.target.classList.contains('solution-image')) {
              solutionImageVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-in')) {
              textVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-up')) {
              solutionTextVisible.value = true;
            }
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      document.querySelectorAll('.text-slide-in, .top-image, .solution-image, .text-slide-up').forEach(element => {
        observer.observe(element);
      });
    });

    return { textVisible, imageVisible, solutionTextVisible, solutionImageVisible };
  },
});
</script>

<style scoped>
/* Allgemeines Styling */
.dark-section {
  background-color: #2c2b2b; /* Dunkler Hintergrund */
  color: #f6f8f9; /* Heller Text für dunklen Hintergrund */
}

.light-section {
  background-color: #ffffff; /* Heller Hintergrund */
  color: #555; /* Dunkler Text für hellen Hintergrund */
}

/* Button-Styling */
.btn-primary {
  background-color: #e2a84b;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.btn-primary:hover {
  background-color: #c75b29;
}

.btn-primary i {
  background-color: #f6f8f9;
  color: #e2a84b;
  border-radius: 50%;
  padding: 5px;
  font-size: 1rem;
}

/* Bild-Styling */
.img-fluid.rounded-img {
  max-height: 350px;
  object-fit: cover;
  border-radius: 30px;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.top-image {
  margin-top: 2rem;
}

.slide-in-active {
  opacity: 1 !important;
}

/* Text-Slide-in Effekt */
.text-slide-in {
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-in-active {
  opacity: 1;
  transform: translateX(0);
}

/* Text-Slide-up Effekt */
.text-slide-up {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-up-active {
  opacity: 1;
  transform: translateY(0);
}
</style>
