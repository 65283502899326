<template>
  <section id="partners" class="py-5">
    <div class="container text-center">
      <h2 class="mb-5">Unsere Partner</h2>
      <div class="row justify-content-center">
        <!-- Dynamisch generierte Partnerkarten -->
        <div
            class="col-md-4 mb-4 partner-card-slide-in"
            :class="{ 'partner-card-slide-in-active': partnerCardsVisible }"
            v-for="partner in partners"
            :key="partner.id"
        >
          <div class="card shadow-sm h-100 d-flex align-items-center justify-content-center">
            <div class="card-body d-flex flex-column align-items-center justify-content-center text-center">
              <img :src="partner.logo" :alt="`Logo von ${partner.name}`" class="img-fluid partner-logo mb-3" />
              <h5 class="card-title">{{ partner.name }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const partners = ref([
      { id: 1, name: 'Bergische Universität Wuppertal', logo: require('@/assets/partner/buw.png') },
      { id: 2, name: 'Institute for Technologies and Management of Digital Transformation', logo: require('@/assets/partner/tmdt.webp') },
      { id: 3, name: 'Interdisziplinäres Zentrum für maschinelles Lernen und Datenanalyse', logo: require('@/assets/partner/izmd.webp') },
      { id: 4, name: 'Mittelstand-Digital Zentrum Handwerk', logo: require('@/assets/partner/mhd.png') },
      { id: 5, name: 'Kreishandwerkerschaft Rhein-Erft', logo: require('@/assets/partner/khre.png') },
      { id: 6, name: 'Bundesverband Garten- und Landschaftsbau', logo: require('@/assets/partner/galabau.jpg') }
    ]);

    const partnerCardsVisible = ref(false);

    onMounted(() => {
      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                partnerCardsVisible.value = true;
                observer.disconnect(); // Stop observing after first appearance
              }
            });
          },
          { threshold: 0.1 }
      );

      const partnersSection = document.querySelector('#partners .row');
      if (partnersSection) observer.observe(partnersSection);
    });

    return { partners, partnerCardsVisible };
  },
});
</script>

<style scoped>
h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3a3a3a;
}

.card {
  border: none;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #f8f9fa;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Zentrale Ausrichtung des Inhalts */
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.card-title {
  font-size: 1.25rem;
  color: #3a3a3a;
}

/* Einheitliche Größe der Logos */
.partner-logo {
  width: 150px; /* Feste Breite */
  height: 100px; /* Feste Höhe */
  object-fit: contain; /* Logos bleiben proportional */
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.card:hover .partner-logo {
  filter: grayscale(0%);
}

/* Slide-in animation for partner cards */
.partner-card-slide-in {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 1s ease-in-out, opacity 0.5s ease-in-out;
}

.partner-card-slide-in-active {
  opacity: 1;
  transform: translateY(0);
}
</style>
