import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/iot/realtime_monitoring.jpg'
import _imports_1 from '@/assets/iot/iot_solution.jpg'


const _hoisted_1 = { class: "iot-realtime-monitoring-page" }
const _hoisted_2 = { class: "section motivation-explanation-section py-5 dark-section" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row align-items-center" }
const _hoisted_5 = { class: "col-md-6 text-center" }
const _hoisted_6 = { class: "section solution-section py-5 dark-section" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "row align-items-center" }
const _hoisted_9 = { class: "col-md-6 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-in", { 'text-slide-in-active': _ctx.textVisible }])
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "highlight-text highlight-text-margin" }, "IoT und Echtzeit-Überwachung", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "lead-light mb-4" }, " Nutzen Sie IoT-Sensoren und Echtzeit-Datenüberwachung, um jederzeit fundierte Entscheidungen zu treffen und Prozesse durch kontinuierliche Einblicke zu optimieren. Erhöhen Sie Ihre Reaktionsfähigkeit und Effizienz durch vernetzte Lösungen. ", -1)),
            _createVNode(_component_router_link, {
              to: { name: 'IoT' },
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Kontaktieren Sie uns "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Motivation für IoT und Echtzeit-Überwachung",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm top-image", { 'slide-in-active': _ctx.imageVisible }])
            }, null, 2)
          ])
        ])
      ])
    ]),
    _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"section solution-overview-section py-5 light-section\" data-v-5fb3b8e4><div class=\"container\" data-v-5fb3b8e4><div class=\"row text-center mb-5\" data-v-5fb3b8e4><div class=\"col-md-4\" data-v-5fb3b8e4><i class=\"fas fa-network-wired fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-5fb3b8e4></i><h4 class=\"highlight-text-dark\" data-v-5fb3b8e4>Umfassende Systemüberwachung</h4><p class=\"lead-dark\" data-v-5fb3b8e4> Überwachen Sie Ihre Systeme umfassend und aus der Ferne, um jederzeit den Zustand Ihrer Infrastruktur im Blick zu behalten. </p></div><div class=\"col-md-4\" data-v-5fb3b8e4><i class=\"fas fa-clock fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-5fb3b8e4></i><h4 class=\"highlight-text-dark\" data-v-5fb3b8e4>Echtzeit-Monitoring</h4><p class=\"lead-dark\" data-v-5fb3b8e4> Nutzen Sie Echtzeit-Monitoring für schnelle Entscheidungsfindung und proaktive Problembehandlung. </p></div><div class=\"col-md-4\" data-v-5fb3b8e4><i class=\"fas fa-signal fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-5fb3b8e4></i><h4 class=\"highlight-text-dark\" data-v-5fb3b8e4>Infrastruktur-Modernisierung</h4><p class=\"lead-dark\" data-v-5fb3b8e4> Digitalisieren und modernisieren Sie Ihre bestehende Infrastruktur, um eine flexible und skalierbare Lösung zu schaffen. </p></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Unsere IoT- und Echtzeit-Überwachungslösung",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm solution-image", { 'slide-in-active': _ctx.solutionImageVisible }])
            }, null, 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-up", { 'text-slide-up-active': _ctx.solutionTextVisible }])
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "highlight-text" }, "Unsere Lösung", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lead-light mb-4" }, " Unsere auf Ihre Anforderungen zugeschnittenen IoT- und Echtzeit-Überwachungslösungen ermöglichen eine präzise Kontrolle und proaktive Wartung Ihrer Systeme. Erhalten Sie kontinuierliche Einblicke und optimieren Sie Prozesse durch nahtlose Vernetzung. ", -1)),
            _createVNode(_component_router_link, {
              to: { name: 'IoT' },
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Jetzt Kontakt aufnehmen "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"section benefits-section py-5 light-section\" data-v-5fb3b8e4><div class=\"container\" data-v-5fb3b8e4><div class=\"row text-center\" data-v-5fb3b8e4><div class=\"col-md-12\" data-v-5fb3b8e4><h2 class=\"highlight-text-dark\" data-v-5fb3b8e4>Ihre Vorteile</h2><p class=\"lead-dark mb-4\" data-v-5fb3b8e4> Erleben Sie die Vorteile von Echtzeit-Überwachung und IoT – für schnelle Reaktionen, fundierte Entscheidungen und eine moderne, vernetzte Infrastruktur. </p></div></div><div class=\"row text-center mt-4\" data-v-5fb3b8e4><div class=\"col-md-4\" data-v-5fb3b8e4><i class=\"fas fa-network-wired fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-5fb3b8e4></i><h4 class=\"highlight-text-dark\" data-v-5fb3b8e4>Fernüberwachung</h4><p class=\"lead-dark\" data-v-5fb3b8e4>Überwachen Sie Ihre Infrastruktur aus der Ferne, um jederzeit und überall Einblicke zu erhalten.</p></div><div class=\"col-md-4\" data-v-5fb3b8e4><i class=\"fas fa-clock fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-5fb3b8e4></i><h4 class=\"highlight-text-dark\" data-v-5fb3b8e4>Schnelle Reaktionen</h4><p class=\"lead-dark\" data-v-5fb3b8e4>Erkennen Sie Probleme frühzeitig und reagieren Sie schneller durch Echtzeit-Einblicke.</p></div><div class=\"col-md-4\" data-v-5fb3b8e4><i class=\"fas fa-signal fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-5fb3b8e4></i><h4 class=\"highlight-text-dark\" data-v-5fb3b8e4>Infrastruktur-Digitalisierung</h4><p class=\"lead-dark\" data-v-5fb3b8e4>Digitalisieren Sie Ihre Systeme und machen Sie Ihre Infrastruktur bereit für die Zukunft.</p></div></div></div></div>", 1))
  ]))
}