<template>
  <section id="services" class="py-5">
    <!-- Einleitung -->
    <div class="intro-text text-center mb-5">
      <h2>Unsere Schwerpunkte und Lösungen</h2>
      <p>Wir kombinieren modernste Technologien mit maßgeschneiderten Dienstleistungen, um Ihre Herausforderungen zu lösen.</p>
    </div>

    <!-- Themen und Dienstleistungen -->
    <div class="container">
      <div class="row">
        <div
            class="col-md-6 col-lg-3 mb-4 card-slide-in"
            :class="{ 'card-slide-in-active': cardsVisible }"
            v-for="theme in themes"
            :key="theme.title"
        >
          <div class="card shadow-sm h-100">
            <div class="card-body d-flex flex-column">
              <div class="d-flex align-items-start mb-3">
                <div class="icon-container d-flex justify-content-center align-items-center">
                  <i :class="theme.icon" class="theme-icon"></i>
                </div>
              </div>
              <h3 class="card-title">{{ theme.title }}</h3>
              <p class="card-text flex-grow-1">{{ theme.description }}</p>
              <!-- Dienstleistungen Überschrift -->
              <div class="services-header mt-3">
                <h4 class="mb-0">Ihre Möglichkeiten</h4>
              </div>
              <!-- Dienstleistungen mit Icons -->
              <ul class="list-unstyled mt-3">
                <li v-for="service in theme.services" :key="service.name" class="d-flex align-items-center">
                  <i :class="service.icon" class="service-icon mr-2"></i>
                  <span>{{ service.name }}</span>
                </li>
              </ul>
              <!-- Call-to-Action -->
              <router-link :to="theme.route" class="btn btn-outline-primary mt-3">
                Weitere Informationen
                <i class="fas fa-arrow-right"></i> <!-- Assuming "fas fa-arrow-right" as the icon -->
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  setup() {
    const themes = ref([
      {
        title: 'Effizientes Datenmanagement',
        description: 'Strukturiertes Datenmanagement zur Verbesserung der Auffindbarkeit, Verständlichkeit und Datenverfügbarkeit durch umfassende Datenkataloge – für eine schnelle Datenfindung und verkürzte Time-to-Analytics.',
        icon: 'fas fa-database',
        route: { name: 'Datenmanagement' },
        services: [
          { name: 'Lassen Sie sich umfassende Datenkataloge aufbauen', icon: 'fas fa-folder-open' },
          { name: 'Sichern und optimieren Sie Ihre Datenqualität', icon: 'fas fa-check-circle' },
          { name: 'Steigern Sie die Verfügbarkeit von Daten in Ihrem Unternehmen', icon: 'fas fa-search' }
        ],
      },
      {
        title: 'Künstliche Intelligenz und Automatisierung',
        description: 'Automatisierung und optimierte Entscheidungsfindung durch KI zur Steigerung der Effizienz und Effektivität Ihrer Geschäftsprozesse – für eine zukunftsorientierte Prozessoptimierung und datenbasierte Prognosen.',
        icon: 'fas fa-robot',
        route: { name: 'KI' },
        services: [
          { name: 'Optimieren Sie Ihre Prozesse effizient durch KI', icon: 'fas fa-tachometer-alt' },
          { name: 'Profitieren Sie von datenbasierten Prognosen und Analysen', icon: 'fas fa-chart-line' },
          { name: 'Nutzen Sie datengetriebene, intelligente Entscheidungsfindung', icon: 'fas fa-brain' }
        ],
      },
      {
        title: 'LLM-basiertes Wissensmanagement',
        description: 'Steigern Sie die Effizienz Ihres Wissensmanagements durch den Einsatz von LLMs wie ChatGPT, um Unternehmenswissen intelligent zu organisieren, zugänglich zu machen und nutzbar zu gestalten.',
        icon: 'fas fa-brain',
        route: { name: 'LLM' },
        services: [
          { name: 'Organisieren Sie Ihr Wissen intelligent mit KI-Unterstützung', icon: 'fas fa-book' },
          { name: 'Erstellen Sie Berichte automatisiert und umfassend', icon: 'fas fa-file-alt' },
          { name: 'Optimieren Sie Ihre Kundenkommunikation proaktiv', icon: 'fas fa-file-alt' },
        ],
      },
      {
        title: 'IoT und Echtzeit-Überwachung',
        description: 'Nutzen Sie IoT-Sensoren und Echtzeit-Datenüberwachung, um jederzeit fundierte Entscheidungen zu treffen und Prozesse durch kontinuierliche Einblicke zu optimieren.',
        icon: 'fas fa-wifi',
        route: { name: 'IoT' },
        services: [
          { name: 'Überwachen Sie Ihre Systeme umfassend und aus der Ferne', icon: 'fas fa-network-wired' },
          { name: 'Nutzen Sie Echtzeit-Monitoring für schnelle Entscheidungsfindung', icon: 'fas fa-clock' },
          { name: 'Digitalisieren und modernisieren Sie Ihre bestehende Infrastruktur', icon: 'fas fa-signal' }
        ],
      }
    ]);

    const cardsVisible = ref(false);

    onMounted(() => {
      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                cardsVisible.value = true;
                observer.disconnect(); // Stop observing after the first appearance
              }
            });
          },
          { threshold: 0.1 }
      );

      const cardsSection = document.querySelector('#services .container');
      if (cardsSection) observer.observe(cardsSection);
    });

    return { themes, cardsVisible };
  },
});
</script>

<style scoped>
/* Stil für die Überschrift und den Einleitungstext */
.intro-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3a3a3a;
}

.intro-text p {
  font-size: 1.2rem;
  color: #555;
}

/* Themenkarten Styling */
.icon-container {
  background-color: #555;
  border-radius: 12px;
  width: 60px;
  height: 60px;
  display: inline-block;
}

.theme-icon {
  font-size: 2rem;
  color: #e2a84b;
}

.card {
  border: none;
  background-color: #f8f9fa;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-size: 1.5rem;
  color: #3a3a3a;
}

/* Center button within the card */
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center button vertically within card */
  align-items: center; /* Center button horizontally within card */
}

.card-text {
  color: #555;
}

ul {
  padding-left: 0;
  list-style-type: none;
}

.service-icon {
  font-size: 1.2rem;
  color: #e2a84b;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  line-height: 24px;
  margin-right: 10px;
}

li {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}

.services-header h4 {
  font-size: 1.25rem;
  color: #3a3a3a;
  margin-bottom: 1rem;
}

/* Centered, Slightly Larger Button Styling */
.btn-outline-primary {
  border-color: #e2a84b;
  background-color: #e2a84b;
  color: #f6f8f9;
  border: none;
  border-radius: 50px;
  padding: 8px 20px; /* Slightly larger padding */
  font-size: 1rem; /* Moderate font size */
  display: inline-flex;
  align-items: center;
  gap: 8px; /* Space between text and icon */
  flex-direction: row; /* Keeps text on left, icon on right */
  justify-content: center;
}

.btn-outline-primary:hover {
  background-color: #c75b29;
  border-color: #c75b29;
}

/* Icon Styling within Button */
.btn-outline-primary i {
  background-color: #ffffff; /* White background for contrast */
  color: #e2a84b; /* Matches button color */
  border-radius: 50%; /* Circular icon background */
  padding: 10px; /* Adjusted padding inside the icon */
  font-size: 1rem; /* Keeps icon size balanced */
}


/* Slide-in animation */
.card-slide-in {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 1s ease-in-out, opacity 0.5s ease-in-out;
}

.card-slide-in-active {
  opacity: 1;
  transform: translateY(0);
}
</style>
