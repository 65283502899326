<template>
  <section id="contact" class="py-5">
    <!-- Einleitung -->
    <div class="intro-text text-center mb-5">
      <h2><i class="fas fa-envelope"></i> Kontaktieren Sie uns</h2>
      <p>Wir freuen uns auf Ihre Nachricht! Füllen Sie das Formular aus und wir werden uns so schnell wie möglich bei Ihnen melden.</p>
    </div>

    <!-- Kontaktformular -->
    <div class="container">
      <form @submit.prevent="submitForm">
        <!-- Name -->
        <div class="input-group mb-3">
          <span class="input-group-text">
            <i class="fas fa-user"></i>
          </span>
          <input type="text" class="form-control" id="name" v-model="formData.name" placeholder="Name" required>
        </div>

        <!-- E-Mail-Adresse -->
        <div class="input-group mb-3">
          <span class="input-group-text">
            <i class="fas fa-envelope"></i>
          </span>
          <input type="email" class="form-control" id="email" v-model="formData.email" placeholder="E-Mail-Adresse" required>
        </div>

        <!-- Nachricht -->
        <div class="input-group mb-3">
          <span class="input-group-text">
            <i class="fas fa-comment-dots"></i>
          </span>
          <textarea class="form-control" id="message" v-model="formData.message" placeholder="Nachricht" rows="5" required></textarea>
        </div>

        <!-- Absenden Button -->
        <button type="submit" class="btn btn-primary d-block w-100">
          <i class="fas fa-paper-plane"></i> Absenden
        </button>
      </form>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ContactForm',
  setup() {
    const formData = ref({
      name: '',
      email: '',
      message: ''
    });

    const submitForm = () => {
      console.log('Formulardaten:', formData.value);
      alert('Vielen Dank für Ihre Nachricht!');
    };

    return { formData, submitForm };
  }
});
</script>

<style scoped>
/* Stil für die Überschrift und den Einleitungstext */
.intro-text h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #3a3a3a;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

h2 i {
  color: #e2a84b;
  margin-right: 10px;
}

.intro-text p {
  font-size: 1.2rem;
  color: #3a3a3a;
}

/* Formularstil */
form {
  max-width: 600px;
  margin: 0 auto;
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Label und Input Icons */
.input-group-text {
  background-color: #e2a84b;
  color: white;
  border: none;
  border-radius: 5px 0 0 5px;
}

.form-control {
  border-radius: 0 5px 5px 0;
  border: 1px solid #ccc;
  padding: 0.75rem;
  font-size: 1rem;
}

textarea.form-control {
  border-radius: 0 5px 5px 0;
}

/* Button Stil */
.btn-primary {
  background-color: #e2a84b;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
}

.btn-primary i {
  margin-right: 10px;
}

.btn-primary:hover {
  background-color: #c75b29;
}

/* Container Stil */
.container {
  background-color: #f8f9fa;
  padding: 2rem;
  border-radius: 10px;
}

.mb-3 {
  margin-bottom: 1.5rem;
}
</style>
