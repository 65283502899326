import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "about-us",
  class: "py-5",
  style: {"background-color":"#2c2b2b"}
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row align-items-center" }
const _hoisted_4 = { class: "lead mb-4" }
const _hoisted_5 = { class: "col-md-6 text-center" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "row text-center mt-5 equal-height-row" }
const _hoisted_8 = { class: "card modern-card shadow-sm hover-card mb-4 w-100" }
const _hoisted_9 = { class: "card-body d-flex flex-column" }
const _hoisted_10 = { class: "card-title" }
const _hoisted_11 = { class: "card-text" }
const _hoisted_12 = { class: "card modern-card shadow-sm hover-card mb-4 w-100" }
const _hoisted_13 = { class: "card-body d-flex flex-column" }
const _hoisted_14 = { class: "card-title" }
const _hoisted_15 = { class: "card-text" }
const _hoisted_16 = { class: "card modern-card shadow-sm hover-card mb-4 w-100" }
const _hoisted_17 = { class: "card-body d-flex flex-column" }
const _hoisted_18 = { class: "card-title" }
const _hoisted_19 = { class: "card-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-in", { 'text-slide-in-active': _ctx.textVisible }])
        }, [
          _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "highlight-text" }, "Ihre Daten sind unser Handwerk", -1)),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.companyInfo.description), 1),
          _createElementVNode("a", {
            href: "#contact",
            class: "btn btn-primary btn-lg mt-2",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/contact')))
          }, _cache[1] || (_cache[1] = [
            _createTextVNode(" Kontaktieren Sie uns "),
            _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
          ]))
        ], 2),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            src: _ctx.companyInfo.image,
            alt: "Company Image",
            class: _normalizeClass(["img-fluid rounded-img shadow-sm", { 'slide-in-active': _ctx.imageVisible }])
          }, null, 10, _hoisted_6)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", {
          class: _normalizeClass(["col-md-4 d-flex align-items-stretch card-slide-in", { 'card-slide-in-active': _ctx.cardsVisible }])
        }, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-database icon" }, null, -1)),
              _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.companyInfo.accessibility.title), 1),
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.companyInfo.accessibility.text), 1)
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col-md-4 d-flex align-items-stretch card-slide-in", { 'card-slide-in-active': _ctx.cardsVisible }])
        }, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[4] || (_cache[4] = _createElementVNode("i", { class: "fas fa-user-tie icon" }, null, -1)),
              _createElementVNode("h5", _hoisted_14, _toDisplayString(_ctx.companyInfo.expertise.title), 1),
              _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.companyInfo.expertise.text), 1)
            ])
          ])
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["col-md-4 d-flex align-items-stretch card-slide-in", { 'card-slide-in-active': _ctx.cardsVisible }])
        }, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-lightbulb icon" }, null, -1)),
              _createElementVNode("h5", _hoisted_18, _toDisplayString(_ctx.companyInfo.innovation.title), 1),
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.companyInfo.innovation.text), 1)
            ])
          ])
        ], 2)
      ])
    ])
  ]))
}