<template>
  <section class="ai-automation-page">

    <!-- Abschnitt 1: Motivation und Erklärung für Künstliche Intelligenz und Automatisierung -->
    <div class="section motivation-explanation-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 mb-4 mb-md-0 text-slide-in" :class="{ 'text-slide-in-active': textVisible }">
            <h2 class="highlight-text highlight-text-margin">Künstliche Intelligenz und Automatisierung</h2>
            <p class="lead-light mb-4">
              Automatisierung und optimierte Entscheidungsfindung durch KI zur Steigerung der Effizienz und Effektivität Ihrer Geschäftsprozesse – für eine zukunftsorientierte Prozessoptimierung und datenbasierte Prognosen.
            </p>
            <router-link :to="{ name: 'KI' }" class="btn btn-primary btn-lg mt-2">
              Kontaktieren Sie uns
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
          <div class="col-md-6 text-center">
            <img
                src="@/assets/ai/automation.jpg"
                alt="Motivation für Künstliche Intelligenz und Automatisierung"
                class="img-fluid rounded-img shadow-sm top-image"
                :class="{ 'slide-in-active': imageVisible }"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 2: Unsere Lösung im Überblick -->
    <div class="section solution-overview-section py-5 light-section">
      <div class="container">
        <div class="row text-center mb-5">
          <div class="col-md-4">
            <i class="fas fa-tachometer-alt fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Effiziente Prozessoptimierung</h4>
            <p class="lead-dark">
              Optimieren Sie Ihre Prozesse effizient durch den Einsatz von KI und steigern Sie die Produktivität in allen Bereichen.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-chart-line fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Datenbasierte Prognosen</h4>
            <p class="lead-dark">
              Profitieren Sie von datenbasierten Prognosen und Analysen für eine präzise Planung und strategische Weitsicht.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-brain fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Intelligente Entscheidungsfindung</h4>
            <p class="lead-dark">
              Nutzen Sie datengetriebene, intelligente Entscheidungsfindung, um fundierte und strategische Entscheidungen zu treffen.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 3: Detaillierte Lösung -->
    <div class="section solution-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center">
            <img
                src="@/assets/ai/solution.jpg"
                alt="Unsere KI- und Automatisierungslösung"
                class="img-fluid rounded-img shadow-sm solution-image"
                :class="{ 'slide-in-active': solutionImageVisible }"
            />
          </div>
          <div class="col-md-6 mb-4 mb-md-0 text-slide-up" :class="{ 'text-slide-up-active': solutionTextVisible }">
            <h2 class="highlight-text">Unsere Lösung</h2>
            <p class="lead-light mb-4">
              Unsere maßgeschneiderten Lösungen für KI und Automatisierung helfen Ihnen, Prozesse zu optimieren, Prognosen zu erstellen und intelligente Entscheidungen zu treffen – für nachhaltigen Erfolg und Effizienzsteigerung in Ihrem Unternehmen.
            </p>
            <router-link :to="{ name: 'KI' }" class="btn btn-primary btn-lg mt-2">
              Jetzt Kontakt aufnehmen
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 4: Vorteile für Ihr Unternehmen -->
    <div class="section benefits-section py-5 light-section">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12">
            <h2 class="highlight-text-dark">Ihre Vorteile</h2>
            <p class="lead-dark mb-4">
              Mit unseren auf Ihr Unternehmen zugeschnittenen KI-gestützten Automatisierung steigern Sie Effizienz, reduzieren Kosten und gewinnen präzise Einblicke für eine strategische Zukunftsplanung.
            </p>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-md-4">
            <i class="fas fa-tachometer-alt fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Prozessgeschwindigkeit erhöhen</h4>
            <p class="lead-dark">Erhöhen Sie die Geschwindigkeit Ihrer Prozesse und minimieren Sie Durchlaufzeiten durch automatisierte Abläufe.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-chart-line fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Präzise Analysen</h4>
            <p class="lead-dark">Gewinnen Sie präzise Einblicke und treffen Sie datenbasierte Entscheidungen für eine strategische Planung.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-brain fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Bessere Entscheidungsfindung</h4>
            <p class="lead-dark">Treffen Sie fundierte Entscheidungen, die durch intelligente Algorithmen gestützt werden.</p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'AIAutomationPage',
  setup() {
    const textVisible = ref(false);
    const imageVisible = ref(false);
    const solutionTextVisible = ref(false);
    const solutionImageVisible = ref(false);

    onMounted(() => {
      const observerOptions = {
        root: null,
        threshold: 0.1,
      };

      const observerCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains('top-image')) {
              imageVisible.value = true;
            } else if (entry.target.classList.contains('solution-image')) {
              solutionImageVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-in')) {
              textVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-up')) {
              solutionTextVisible.value = true;
            }
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      document.querySelectorAll('.text-slide-in, .top-image, .solution-image, .text-slide-up').forEach(element => {
        observer.observe(element);
      });
    });

    return { textVisible, imageVisible, solutionTextVisible, solutionImageVisible };
  },
});
</script>

<style scoped>
.dark-section {
  background-color: #2c2b2b;
  color: #f6f8f9;
}

.light-section {
  background-color: #ffffff;
  color: #555;
}

.btn-primary {
  background-color: #e2a84b;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.btn-primary:hover {
  background-color: #c75b29;
}

.btn-primary i {
  background-color: #f6f8f9;
  color: #e2a84b;
  border-radius: 50%;
  padding: 5px;
  font-size: 1rem;
}

.img-fluid.rounded-img {
  max-height: 350px;
  object-fit: cover;
  border-radius: 30px;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.top-image {
  margin-top: 2rem;
}

.slide-in-active {
  opacity: 1 !important;
}

.text-slide-in {
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-in-active {
  opacity: 1;
  transform: translateX(0);
}

.text-slide-up {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-up-active {
  opacity: 1;
  transform: translateY(0);
}
</style>
