<template>
  <section class="llm-knowledge-management-page">

    <!-- Abschnitt 1: Motivation und Erklärung für LLM-basiertes Wissensmanagement -->
    <div class="section motivation-explanation-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 mb-4 mb-md-0 text-slide-in" :class="{ 'text-slide-in-active': textVisible }">
            <h2 class="highlight-text highlight-text-margin">LLM-basiertes Wissensmanagement</h2>
            <p class="lead-light mb-4">
              Steigern Sie die Effizienz Ihres Wissensmanagements durch den Einsatz von LLMs wie ChatGPT, um Unternehmenswissen intelligent zu organisieren, zugänglich zu machen und nutzbar zu gestalten. Nutzen Sie KI, um Ihr Wissen optimal zu verwalten und eine effektive Entscheidungsfindung zu fördern.
            </p>
            <router-link :to="{ name: 'LLM' }" class="btn btn-primary btn-lg mt-2">
              Kontaktieren Sie uns
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
          <div class="col-md-6 text-center">
            <img
                src="@/assets/llm/llm.jpg"
                alt="Motivation für LLM-basiertes Wissensmanagement"
                class="img-fluid rounded-img shadow-sm top-image"
                :class="{ 'slide-in-active': imageVisible }"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 2: Unsere Lösung im Überblick -->
    <div class="section solution-overview-section py-5 light-section">
      <div class="container">
        <div class="row text-center mb-5">
          <div class="col-md-4">
            <i class="fas fa-book fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">KI-gestütztes Wissen organisieren</h4>
            <p class="lead-dark">
              Organisieren Sie Ihr Wissen intelligent mit KI-Unterstützung und machen Sie es leicht zugänglich für alle Mitarbeiter.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-file-alt fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Automatisierte Berichterstellung</h4>
            <p class="lead-dark">
              Erstellen Sie Berichte automatisiert und umfassend, um die Effizienz Ihrer internen Kommunikation zu steigern.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-headset fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Optimierte Kundenkommunikation</h4>
            <p class="lead-dark">
              Verbessern Sie Ihre Kundenkommunikation proaktiv durch maßgeschneiderte Antworten und erhöhte Effizienz.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 3: Detaillierte Lösung -->
    <div class="section solution-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center">
            <img
                src="@/assets/llm/llm2.jpg"
                alt="Unsere LLM-basierte Wissensmanagement-Lösung"
                class="img-fluid rounded-img shadow-sm solution-image"
                :class="{ 'slide-in-active': solutionImageVisible }"
            />
          </div>
          <div class="col-md-6 mb-4 mb-md-0 text-slide-up" :class="{ 'text-slide-up-active': solutionTextVisible }">
            <h2 class="highlight-text">Unsere Lösung</h2>
            <p class="lead-light mb-4">
              Mit unseren maßgeschneiderten LLM-basierten Wissensmanagement-Lösungen können Sie Wissen effizient verwalten, komplexe Berichte erstellen und eine optimierte Kommunikation aufbauen – für ein dynamisches, zukunftsorientiertes Unternehmen.
            </p>
            <router-link :to="{ name: 'LLM' }" class="btn btn-primary btn-lg mt-2">
              Jetzt Kontakt aufnehmen
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 4: Vorteile für Ihr Unternehmen -->
    <div class="section benefits-section py-5 light-section">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12">
            <h2 class="highlight-text-dark">Ihre Vorteile</h2>
            <p class="lead-dark mb-4">
              Profitieren Sie von intelligentem Wissensmanagement durch LLMs und heben Sie Ihre Entscheidungsfindung und Effizienz auf das nächste Level.
            </p>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-md-4">
            <i class="fas fa-brain fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Intelligente Wissensorganisation</h4>
            <p class="lead-dark">KI unterstützt die strukturierte Organisation Ihres Wissens, um eine fundierte Entscheidungsbasis zu schaffen.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-file-alt fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Umfassende Berichte</h4>
            <p class="lead-dark">Erstellen Sie detaillierte Berichte schnell und effizient mit KI-Unterstützung für maximale Transparenz.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-headset fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Optimierte Kommunikation</h4>
            <p class="lead-dark">Verbessern Sie die Kundenkommunikation und bieten Sie maßgeschneiderte, proaktive Antworten.</p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'LLMKnowledgeManagementPage',
  setup() {
    const textVisible = ref(false);
    const imageVisible = ref(false);
    const solutionTextVisible = ref(false);
    const solutionImageVisible = ref(false);

    onMounted(() => {
      const observerOptions = {
        root: null,
        threshold: 0.1,
      };

      const observerCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains('top-image')) {
              imageVisible.value = true;
            } else if (entry.target.classList.contains('solution-image')) {
              solutionImageVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-in')) {
              textVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-up')) {
              solutionTextVisible.value = true;
            }
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      document.querySelectorAll('.text-slide-in, .top-image, .solution-image, .text-slide-up').forEach(element => {
        observer.observe(element);
      });
    });

    return { textVisible, imageVisible, solutionTextVisible, solutionImageVisible };
  },
});
</script>

<style scoped>
.dark-section {
  background-color: #2c2b2b;
  color: #f6f8f9;
}

.light-section {
  background-color: #ffffff;
  color: #555;
}

.btn-primary {
  background-color: #e2a84b;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.btn-primary:hover {
  background-color: #c75b29;
}

.btn-primary i {
  background-color: #f6f8f9;
  color: #e2a84b;
  border-radius: 50%;
  padding: 5px;
  font-size: 1rem;
}

.img-fluid.rounded-img {
  max-height: 350px;
  object-fit: cover;
  border-radius: 30px;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.top-image {
  margin-top: 2rem;
}

.slide-in-active {
  opacity: 1 !important;
}

.text-slide-in {
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-in-active {
  opacity: 1;
  transform: translateX(0);
}

.text-slide-up {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-up-active {
  opacity: 1;
  transform: translateY(0);
}
</style>
