<template>
  <section id="team" class="py-5" style="background-color: #2c2b2b;">
    <div class="container text-center">
      <h2 class="mb-5" style="color: #f6f8f9;">Unser Team</h2>
      <div class="row justify-content-center">
        <!-- Dynamisch generierte Teamkarten -->
        <div
            class="col-md-4 mb-4 team-card-slide-in"
            :class="{ 'team-card-slide-in-active': teamCardsVisible }"
            v-for="member in team"
            :key="member.id"
        >
          <div class="card shadow-sm h-100 hover-card modern-card">
            <img :src="member.photo" :alt="member.name" class="card-img-top rounded-circle" />
            <div class="card-body d-flex flex-column align-items-center">
              <h5 class="card-title">{{ member.name }}</h5>
              <p class="card-text">{{ member.role }}</p>
              <p class="card-description">{{ member.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'CompanyTeam',
  setup() {
    const team = ref([
      {
        id: 1,
        name: 'Dr.-Ing. André Pomp',
        role: 'Geschäftsführer',
        description:
            'André ist unser Visionär für Datenmanagement und künstliche Intelligenz. Mit seinem umfassenden Wissen über große Sprachmodelle gestaltet er innovative KI-Lösungen und führt ihr Unternehmen in die Zukunft.',
        photo: require('@/assets/team/andre.jpg'),
      },
      {
        id: 2,
        name: 'Dr.-Ing. Alexander Paulus',
        role: 'Geschäftsführer',
        description:
            'Alex ist der kreative Kopf hinter unseren Datenstrategien und Softwarelösungen. Er kombiniert fundiertes Wissen im Bereich Softwareentwicklung und -design, um herausragende digitale Erlebnisse zu schaffen.',
        photo: require('@/assets/team/alex.jpg'),
      },
    ]);

    const teamCardsVisible = ref(false);

    onMounted(() => {
      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                teamCardsVisible.value = true;
                observer.disconnect();
              }
            });
          },
          { threshold: 0.1 }
      );

      const teamSection = document.querySelector('#team .row');
      if (teamSection) observer.observe(teamSection);
    });

    return { team, teamCardsVisible };
  },
});
</script>

<style scoped>
/* Stil für die Team-Sektionsüberschrift */
h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #f6f8f9;
  margin-bottom: 40px;
}

/* Kartenstil mit modernerer Optik */
.modern-card {
  background-color: #3a3a3a;
  border: 1px solid #444;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.modern-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

/* Bildstil */
.card-img-top {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Titelstil */
.card-title {
  font-size: 1.5rem;
  color: #e2a84b;
  margin-bottom: 10px;
}

/* Rollentextstil */
.card-text {
  color: #f6f8f9;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

/* Beschreibungsstil */
.card-description {
  color: #d1d1d1;
  font-size: 0.95rem;
  text-align: center;
  margin-top: 8px;
}

/* Slide-in animation for team cards */
.team-card-slide-in {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 1s ease-in-out, opacity 0.5s ease-in-out;
}

.team-card-slide-in-active {
  opacity: 1;
  transform: translateY(0);
}
</style>
