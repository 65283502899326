import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/ai/automation.jpg'
import _imports_1 from '@/assets/ai/solution.jpg'


const _hoisted_1 = { class: "ai-automation-page" }
const _hoisted_2 = { class: "section motivation-explanation-section py-5 dark-section" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row align-items-center" }
const _hoisted_5 = { class: "col-md-6 text-center" }
const _hoisted_6 = { class: "section solution-section py-5 dark-section" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "row align-items-center" }
const _hoisted_9 = { class: "col-md-6 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-in", { 'text-slide-in-active': _ctx.textVisible }])
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "highlight-text highlight-text-margin" }, "Künstliche Intelligenz und Automatisierung", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "lead-light mb-4" }, " Automatisierung und optimierte Entscheidungsfindung durch KI zur Steigerung der Effizienz und Effektivität Ihrer Geschäftsprozesse – für eine zukunftsorientierte Prozessoptimierung und datenbasierte Prognosen. ", -1)),
            _createVNode(_component_router_link, {
              to: { name: 'KI' },
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Kontaktieren Sie uns "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Motivation für Künstliche Intelligenz und Automatisierung",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm top-image", { 'slide-in-active': _ctx.imageVisible }])
            }, null, 2)
          ])
        ])
      ])
    ]),
    _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"section solution-overview-section py-5 light-section\" data-v-6696bc50><div class=\"container\" data-v-6696bc50><div class=\"row text-center mb-5\" data-v-6696bc50><div class=\"col-md-4\" data-v-6696bc50><i class=\"fas fa-tachometer-alt fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-6696bc50></i><h4 class=\"highlight-text-dark\" data-v-6696bc50>Effiziente Prozessoptimierung</h4><p class=\"lead-dark\" data-v-6696bc50> Optimieren Sie Ihre Prozesse effizient durch den Einsatz von KI und steigern Sie die Produktivität in allen Bereichen. </p></div><div class=\"col-md-4\" data-v-6696bc50><i class=\"fas fa-chart-line fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-6696bc50></i><h4 class=\"highlight-text-dark\" data-v-6696bc50>Datenbasierte Prognosen</h4><p class=\"lead-dark\" data-v-6696bc50> Profitieren Sie von datenbasierten Prognosen und Analysen für eine präzise Planung und strategische Weitsicht. </p></div><div class=\"col-md-4\" data-v-6696bc50><i class=\"fas fa-brain fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-6696bc50></i><h4 class=\"highlight-text-dark\" data-v-6696bc50>Intelligente Entscheidungsfindung</h4><p class=\"lead-dark\" data-v-6696bc50> Nutzen Sie datengetriebene, intelligente Entscheidungsfindung, um fundierte und strategische Entscheidungen zu treffen. </p></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Unsere KI- und Automatisierungslösung",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm solution-image", { 'slide-in-active': _ctx.solutionImageVisible }])
            }, null, 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-up", { 'text-slide-up-active': _ctx.solutionTextVisible }])
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "highlight-text" }, "Unsere Lösung", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lead-light mb-4" }, " Unsere maßgeschneiderten Lösungen für KI und Automatisierung helfen Ihnen, Prozesse zu optimieren, Prognosen zu erstellen und intelligente Entscheidungen zu treffen – für nachhaltigen Erfolg und Effizienzsteigerung in Ihrem Unternehmen. ", -1)),
            _createVNode(_component_router_link, {
              to: { name: 'KI' },
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Jetzt Kontakt aufnehmen "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"section benefits-section py-5 light-section\" data-v-6696bc50><div class=\"container\" data-v-6696bc50><div class=\"row text-center\" data-v-6696bc50><div class=\"col-md-12\" data-v-6696bc50><h2 class=\"highlight-text-dark\" data-v-6696bc50>Ihre Vorteile</h2><p class=\"lead-dark mb-4\" data-v-6696bc50> Mit unseren auf Ihr Unternehmen zugeschnittenen KI-gestützten Automatisierung steigern Sie Effizienz, reduzieren Kosten und gewinnen präzise Einblicke für eine strategische Zukunftsplanung. </p></div></div><div class=\"row text-center mt-4\" data-v-6696bc50><div class=\"col-md-4\" data-v-6696bc50><i class=\"fas fa-tachometer-alt fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-6696bc50></i><h4 class=\"highlight-text-dark\" data-v-6696bc50>Prozessgeschwindigkeit erhöhen</h4><p class=\"lead-dark\" data-v-6696bc50>Erhöhen Sie die Geschwindigkeit Ihrer Prozesse und minimieren Sie Durchlaufzeiten durch automatisierte Abläufe.</p></div><div class=\"col-md-4\" data-v-6696bc50><i class=\"fas fa-chart-line fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-6696bc50></i><h4 class=\"highlight-text-dark\" data-v-6696bc50>Präzise Analysen</h4><p class=\"lead-dark\" data-v-6696bc50>Gewinnen Sie präzise Einblicke und treffen Sie datenbasierte Entscheidungen für eine strategische Planung.</p></div><div class=\"col-md-4\" data-v-6696bc50><i class=\"fas fa-brain fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-6696bc50></i><h4 class=\"highlight-text-dark\" data-v-6696bc50>Bessere Entscheidungsfindung</h4><p class=\"lead-dark\" data-v-6696bc50>Treffen Sie fundierte Entscheidungen, die durch intelligente Algorithmen gestützt werden.</p></div></div></div></div>", 1))
  ]))
}