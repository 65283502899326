<template>
  <section class="iot-realtime-monitoring-page">

    <!-- Abschnitt 1: Motivation und Erklärung für IoT und Echtzeit-Überwachung -->
    <div class="section motivation-explanation-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 mb-4 mb-md-0 text-slide-in" :class="{ 'text-slide-in-active': textVisible }">
            <h2 class="highlight-text highlight-text-margin">IoT und Echtzeit-Überwachung</h2>
            <p class="lead-light mb-4">
              Nutzen Sie IoT-Sensoren und Echtzeit-Datenüberwachung, um jederzeit fundierte Entscheidungen zu treffen und Prozesse durch kontinuierliche Einblicke zu optimieren. Erhöhen Sie Ihre Reaktionsfähigkeit und Effizienz durch vernetzte Lösungen.
            </p>
            <router-link :to="{ name: 'IoT' }" class="btn btn-primary btn-lg mt-2">
              Kontaktieren Sie uns
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
          <div class="col-md-6 text-center">
            <img
                src="@/assets/iot/realtime_monitoring.jpg"
                alt="Motivation für IoT und Echtzeit-Überwachung"
                class="img-fluid rounded-img shadow-sm top-image"
                :class="{ 'slide-in-active': imageVisible }"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 2: Unsere Lösung im Überblick -->
    <div class="section solution-overview-section py-5 light-section">
      <div class="container">
        <div class="row text-center mb-5">
          <div class="col-md-4">
            <i class="fas fa-network-wired fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Umfassende Systemüberwachung</h4>
            <p class="lead-dark">
              Überwachen Sie Ihre Systeme umfassend und aus der Ferne, um jederzeit den Zustand Ihrer Infrastruktur im Blick zu behalten.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-clock fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Echtzeit-Monitoring</h4>
            <p class="lead-dark">
              Nutzen Sie Echtzeit-Monitoring für schnelle Entscheidungsfindung und proaktive Problembehandlung.
            </p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-signal fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Infrastruktur-Modernisierung</h4>
            <p class="lead-dark">
              Digitalisieren und modernisieren Sie Ihre bestehende Infrastruktur, um eine flexible und skalierbare Lösung zu schaffen.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 3: Detaillierte Lösung -->
    <div class="section solution-section py-5 dark-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 text-center">
            <img
                src="@/assets/iot/iot_solution.jpg"
                alt="Unsere IoT- und Echtzeit-Überwachungslösung"
                class="img-fluid rounded-img shadow-sm solution-image"
                :class="{ 'slide-in-active': solutionImageVisible }"
            />
          </div>
          <div class="col-md-6 mb-4 mb-md-0 text-slide-up" :class="{ 'text-slide-up-active': solutionTextVisible }">
            <h2 class="highlight-text">Unsere Lösung</h2>
            <p class="lead-light mb-4">
              Unsere auf Ihre Anforderungen zugeschnittenen IoT- und Echtzeit-Überwachungslösungen ermöglichen eine präzise Kontrolle und proaktive Wartung Ihrer Systeme. Erhalten Sie kontinuierliche Einblicke und optimieren Sie Prozesse durch nahtlose Vernetzung.
            </p>
            <router-link :to="{ name: 'IoT' }" class="btn btn-primary btn-lg mt-2">
              Jetzt Kontakt aufnehmen
              <i class="fas fa-envelope"></i>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- Abschnitt 4: Vorteile für Ihr Unternehmen -->
    <div class="section benefits-section py-5 light-section">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12">
            <h2 class="highlight-text-dark">Ihre Vorteile</h2>
            <p class="lead-dark mb-4">
              Erleben Sie die Vorteile von Echtzeit-Überwachung und IoT – für schnelle Reaktionen, fundierte Entscheidungen und eine moderne, vernetzte Infrastruktur.
            </p>
          </div>
        </div>
        <div class="row text-center mt-4">
          <div class="col-md-4">
            <i class="fas fa-network-wired fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Fernüberwachung</h4>
            <p class="lead-dark">Überwachen Sie Ihre Infrastruktur aus der Ferne, um jederzeit und überall Einblicke zu erhalten.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-clock fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Schnelle Reaktionen</h4>
            <p class="lead-dark">Erkennen Sie Probleme frühzeitig und reagieren Sie schneller durch Echtzeit-Einblicke.</p>
          </div>
          <div class="col-md-4">
            <i class="fas fa-signal fa-3x mb-3" style="color: #e2a84b;"></i>
            <h4 class="highlight-text-dark">Infrastruktur-Digitalisierung</h4>
            <p class="lead-dark">Digitalisieren Sie Ihre Systeme und machen Sie Ihre Infrastruktur bereit für die Zukunft.</p>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'IoTRealtimeMonitoringPage',
  setup() {
    const textVisible = ref(false);
    const imageVisible = ref(false);
    const solutionTextVisible = ref(false);
    const solutionImageVisible = ref(false);

    onMounted(() => {
      const observerOptions = {
        root: null,
        threshold: 0.1,
      };

      const observerCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains('top-image')) {
              imageVisible.value = true;
            } else if (entry.target.classList.contains('solution-image')) {
              solutionImageVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-in')) {
              textVisible.value = true;
            } else if (entry.target.classList.contains('text-slide-up')) {
              solutionTextVisible.value = true;
            }
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      document.querySelectorAll('.text-slide-in, .top-image, .solution-image, .text-slide-up').forEach(element => {
        observer.observe(element);
      });
    });

    return { textVisible, imageVisible, solutionTextVisible, solutionImageVisible };
  },
});
</script>

<style scoped>
.dark-section {
  background-color: #2c2b2b;
  color: #f6f8f9;
}

.light-section {
  background-color: #ffffff;
  color: #555;
}

.btn-primary {
  background-color: #e2a84b;
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.btn-primary:hover {
  background-color: #c75b29;
}

.btn-primary i {
  background-color: #f6f8f9;
  color: #e2a84b;
  border-radius: 50%;
  padding: 5px;
  font-size: 1rem;
}

.img-fluid.rounded-img {
  max-height: 350px;
  object-fit: cover;
  border-radius: 30px;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.top-image {
  margin-top: 2rem;
}

.slide-in-active {
  opacity: 1 !important;
}

.text-slide-in {
  opacity: 0;
  transform: translateX(-20px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-in-active {
  opacity: 1;
  transform: translateX(0);
}

.text-slide-up {
  opacity: 0;
  transform: translateY(40px);
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

.text-slide-up-active {
  opacity: 1;
  transform: translateY(0);
}
</style>
