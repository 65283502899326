import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-white py-4" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-md-6 text-md-end text-center" }
const _hoisted_5 = { class: "legal-info" }
const _hoisted_6 = { class: "legal-item" }
const _hoisted_7 = { class: "legal-item" }
const _hoisted_8 = { class: "legal-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"col-md-6 text-md-start text-center mb-4 mb-md-0\" data-v-41b96bd2><div class=\"contact-info\" data-v-41b96bd2><p data-v-41b96bd2><i class=\"fas fa-envelope\" data-v-41b96bd2></i><a href=\"mailto:info@crafting-systems.de\" class=\"custom-link\" data-v-41b96bd2> info@crafting-systems.de </a></p><p data-v-41b96bd2><i class=\"fas fa-phone\" data-v-41b96bd2></i><a href=\"tel:+4915162987881\" class=\"custom-link\" data-v-41b96bd2> +49 151 629 878 81 </a></p></div></div>", 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createVNode(_component_router_link, {
                to: "/impressum",
                class: "custom-link"
              }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("Impressum")
                ])),
                _: 1
              }),
              _cache[1] || (_cache[1] = _createElementVNode("i", { class: "fas fa-gavel" }, null, -1))
            ]),
            _createElementVNode("p", _hoisted_7, [
              _createVNode(_component_router_link, {
                to: "/datenschutz",
                class: "custom-link"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Datenschutz")
                ])),
                _: 1
              }),
              _cache[3] || (_cache[3] = _createElementVNode("i", { class: "fas fa-shield-alt" }, null, -1))
            ]),
            _createElementVNode("p", _hoisted_8, [
              _createVNode(_component_router_link, {
                to: "/contact",
                class: "custom-link"
              }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Kontakt")
                ])),
                _: 1
              }),
              _cache[5] || (_cache[5] = _createElementVNode("i", { class: "fas fa-envelope" }, null, -1))
            ])
          ])
        ])
      ]),
      _cache[7] || (_cache[7] = _createStaticVNode("<hr class=\"divider-line\" data-v-41b96bd2><div class=\"row align-items-center pt-3\" data-v-41b96bd2><div class=\"col-md-6 text-md-start text-center mb-2 mb-md-0\" data-v-41b96bd2><p class=\"mb-0\" data-v-41b96bd2>© 2024 Crafting Systems GmbH. Alle Rechte vorbehalten.</p></div><div class=\"col-md-6 text-md-end text-center\" data-v-41b96bd2><div class=\"social-icons\" data-v-41b96bd2><a href=\"https://www.linkedin.com/company/100260640/\" target=\"_blank\" class=\"social-link\" data-v-41b96bd2><i class=\"fab fa-linkedin\" data-v-41b96bd2></i></a><a href=\"https://github.com/Crafting-Systems\" target=\"_blank\" class=\"social-link\" data-v-41b96bd2><i class=\"fab fa-github\" data-v-41b96bd2></i></a></div></div></div>", 2))
    ])
  ]))
}