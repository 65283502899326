<template>
  <section id="about-us" class="py-5" style="background-color: #2c2b2b;">
    <div class="container">
      <!-- Text und Bild mit Call-to-Action -->
      <div class="row align-items-center">
        <!-- Text rechts -->
        <div
            class="col-md-6 mb-4 mb-md-0 text-slide-in"
            :class="{ 'text-slide-in-active': textVisible }"
        >
          <h2 class="highlight-text">Ihre Daten sind unser Handwerk</h2>
          <p class="lead mb-4">{{ companyInfo.description }}</p>
          <a href="#contact" class="btn btn-primary btn-lg mt-2" @click="$router.push('/contact')">
            Kontaktieren Sie uns
            <i class="fas fa-envelope"></i>
          </a>
        </div>

        <!-- Bild links -->
        <div class="col-md-6 text-center">
          <img
              :src="companyInfo.image"
              alt="Company Image"
              class="img-fluid rounded-img shadow-sm"
              :class="{ 'slide-in-active': imageVisible }"
          />
        </div>
      </div>

      <!-- Sektionen: Datenzugänglichkeit, Expertise, Innovation -->
      <div class="row text-center mt-5 equal-height-row">
        <!-- Datenzugänglichkeit -->
        <div
            class="col-md-4 d-flex align-items-stretch card-slide-in"
            :class="{ 'card-slide-in-active': cardsVisible }"
        >
          <div class="card modern-card shadow-sm hover-card mb-4 w-100">
            <div class="card-body d-flex flex-column">
              <i class="fas fa-database icon"></i>
              <h5 class="card-title">{{ companyInfo.accessibility.title }}</h5>
              <p class="card-text">{{ companyInfo.accessibility.text }}</p>
            </div>
          </div>
        </div>
        <!-- Expertise -->
        <div
            class="col-md-4 d-flex align-items-stretch card-slide-in"
            :class="{ 'card-slide-in-active': cardsVisible }"
        >
          <div class="card modern-card shadow-sm hover-card mb-4 w-100">
            <div class="card-body d-flex flex-column">
              <i class="fas fa-user-tie icon"></i>
              <h5 class="card-title">{{ companyInfo.expertise.title }}</h5>
              <p class="card-text">{{ companyInfo.expertise.text }}</p>
            </div>
          </div>
        </div>
        <!-- Innovation -->
        <div
            class="col-md-4 d-flex align-items-stretch card-slide-in"
            :class="{ 'card-slide-in-active': cardsVisible }"
        >
          <div class="card modern-card shadow-sm hover-card mb-4 w-100">
            <div class="card-body d-flex flex-column">
              <i class="fas fa-lightbulb icon"></i>
              <h5 class="card-title">{{ companyInfo.innovation.title }}</h5>
              <p class="card-text">{{ companyInfo.innovation.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'CompanyAboutUs',
  setup() {
    const companyInfo = ref({
      image: require('@/assets/about/theme.jpg'),
      description:
          'In der heutigen datengetriebenen Welt sind Informationen der Schlüssel zum Erfolg. Wir bieten maßgeschneiderte Lösungen, um sicherzustellen, dass Sie Ihre Daten optimal nutzen können.',
      accessibility: {
        title: 'Datenzugänglichkeit',
        text: 'Was nützen Daten, wenn sie nicht zugänglich und nutzbar sind? Wir schaffen Lösungen für Datenzugänglichkeit und helfen Ihnen, das volle Potenzial Ihrer Daten zu entfalten.',
      },
      expertise: {
        title: 'Unsere Expertise',
        text: 'Vertrauen Sie auf unsere Erfahrung und unser Fachwissen, um fundierte Entscheidungen zu treffen und Innovationen in Ihrem Unternehmen voranzutreiben.',
      },
      innovation: {
        title: 'Innovation',
        text: 'Nutzen Sie unser Know-how, um Innovationen zu fördern und neue Wege in der datengestützten Welt zu beschreiten.',
      },
    });

    const imageVisible = ref(false);
    const textVisible = ref(false);
    const cardsVisible = ref(false);

    onMounted(() => {
      // Trigger the animations for text, image, and cards
      setTimeout(() => {
        textVisible.value = true;
        imageVisible.value = true;
        cardsVisible.value = true;
      }, 50); // Delay to trigger the animation after the component is mounted
    });

    return { companyInfo, imageVisible, textVisible, cardsVisible };
  },
});
</script>

<style scoped>
/* Stil für die hervorgehobene Überschrift */
.highlight-text {
  font-size: 2.5rem;
  margin-top: 2rem; /* Abstand nach oben */
  font-weight: bold;
  color: #f6f8f9;
  margin-bottom: 20px;
}

/* Allgemeines Styling */
.lead {
  font-size: 1.25rem;
  color: #f6f8f9;
}

/* Call-to-Action Button Styling */
.btn-primary {
  background-color: #e2a84b;
  border: none;
  border-radius: 50px;
  padding: 10px 20px 10px 30px; /* Reduced padding on the right */
  font-size: 1rem;
  display: inline-flex;
  align-items: center;
  gap: 10px; /* Space between text and icon */
}

.btn-primary:hover {
  background-color: #c75b29;
}

/* Icon Styling within Button */
.btn-primary i {
  background-color: #f6f8f9; /* White background for contrast */
  color: #e2a84b; /* Matches button color */
  border-radius: 50%; /* Circular icon background */
  padding: 5px; /* Adds padding inside the icon */
  font-size: 1rem;
  margin-left: 10px; /* Extra space between text and icon */
}


/* Bild mit abgerundeten Ecken */
.img-fluid.rounded-img {
  max-height: 350px;
  margin-top: 2rem; /* Abstand nach oben */
  object-fit: cover;
  border-radius: 30px;
  opacity: 0; /* Hidden by default */
  transition: opacity 1.5s ease-in-out; /* Longer duration and smooth easing */
}

/* Fade-in animation activated */
.slide-in-active {
  opacity: 1 !important; /* Fade in */
}

/* Text and button container */
.text-slide-in {
  opacity: 0; /* Hidden by default */
  transform: translateX(-20px); /* Start slightly off-screen to the left */
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

/* When the text becomes visible */
.text-slide-in-active {
  opacity: 1; /* Fade in */
  transform: translateX(0); /* Move into position */
}

/* Card container for slide-in effect */
.card-slide-in {
  opacity: 0; /* Hidden by default */
  transform: translateY(20px); /* Start slightly below */
  transition: transform 1.5s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 1.5s ease-in-out;
}

/* When the cards become visible */
.card-slide-in-active {
  opacity: 1; /* Fade in */
  transform: translateY(0); /* Slide up slightly into position */
}

/* Icon Styling für die Boxen */
.icon {
  font-size: 2.5rem;
  color: #e2a84b;
  margin-bottom: 20px;
}

/* Modernisierte Karten (ähnlich wie im Team-Bereich) */
.modern-card {
  background-color: #3a3a3a;
  border: 1px solid #444;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.modern-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

/* Flexbox für gleiche Höhe der Boxen */
.equal-height-row {
  display: flex;
  flex-wrap: wrap;
}

.equal-height-row .col-md-4 {
  display: flex;
  flex-direction: column;
}

.hover-card {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-title {
  font-size: 1.5rem;
  color: #e2a84b;
}

.card-text {
  color: #f6f8f9;
  flex-grow: 1;
}
</style>
