import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/cs-logo.svg'


const _hoisted_1 = { class: "navbar navbar-expand-lg navbar-dark fixed-top" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = {
  class: "collapse navbar-collapse",
  id: "navbarNav"
}
const _hoisted_4 = { class: "navbar-nav ms-auto" }
const _hoisted_5 = { class: "nav-item" }
const _hoisted_6 = { class: "nav-item" }
const _hoisted_7 = { class: "nav-item" }
const _hoisted_8 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[5] || (_cache[5] = _createElementVNode("img", {
        src: _imports_0,
        width: "90",
        alt: "CS logo"
      }, null, -1)),
      _createVNode(_component_router_link, {
        class: "navbar-brand",
        to: { path: '/' }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode("Crafting Systems GmbH")
        ])),
        _: 1
      }),
      _cache[6] || (_cache[6] = _createElementVNode("button", {
        class: "navbar-toggler",
        type: "button",
        "data-bs-toggle": "collapse",
        "data-bs-target": "#navbarNav",
        "aria-controls": "navbarNav",
        "aria-expanded": "false",
        "aria-label": "Toggle navigation"
      }, [
        _createElementVNode("span", { class: "navbar-toggler-icon" })
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("ul", _hoisted_4, [
          _createElementVNode("li", _hoisted_5, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(["nav-link", _ctx.activeClass('about')]),
              to: { path: '/', hash: '#about' }
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createElementVNode("i", { class: "fas fa-info-circle" }, null, -1),
                _createTextVNode(" Über Uns ")
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", _hoisted_6, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(["nav-link", _ctx.activeClass('services')]),
              to: { path: '/', hash: '#services' }
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createElementVNode("i", { class: "fas fa-tools" }, null, -1),
                _createTextVNode(" Lösungen ")
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", _hoisted_7, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(["nav-link", _ctx.activeClass('team')]),
              to: { path: '/', hash: '#team' }
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("i", { class: "fas fa-users" }, null, -1),
                _createTextVNode(" Team ")
              ])),
              _: 1
            }, 8, ["class"])
          ]),
          _createElementVNode("li", _hoisted_8, [
            _createVNode(_component_router_link, {
              class: _normalizeClass(["nav-link", _ctx.activeClass('partners')]),
              to: { path: '/', hash: '#partners' }
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createElementVNode("i", { class: "fas fa-handshake" }, null, -1),
                _createTextVNode(" Partner ")
              ])),
              _: 1
            }, 8, ["class"])
          ])
        ])
      ])
    ])
  ]))
}