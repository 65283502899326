import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "contact",
  class: "py-5"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "input-group mb-3" }
const _hoisted_4 = { class: "input-group mb-3" }
const _hoisted_5 = { class: "input-group mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[8] || (_cache[8] = _createElementVNode("div", { class: "intro-text text-center mb-5" }, [
      _createElementVNode("h2", null, [
        _createElementVNode("i", { class: "fas fa-envelope" }),
        _createTextVNode(" Kontaktieren Sie uns")
      ]),
      _createElementVNode("p", null, "Wir freuen uns auf Ihre Nachricht! Füllen Sie das Formular aus und wir werden uns so schnell wie möglich bei Ihnen melden.")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "input-group-text" }, [
            _createElementVNode("i", { class: "fas fa-user" })
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            id: "name",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
            placeholder: "Name",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.formData.name]
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("span", { class: "input-group-text" }, [
            _createElementVNode("i", { class: "fas fa-envelope" })
          ], -1)),
          _withDirectives(_createElementVNode("input", {
            type: "email",
            class: "form-control",
            id: "email",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.email) = $event)),
            placeholder: "E-Mail-Adresse",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.formData.email]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[6] || (_cache[6] = _createElementVNode("span", { class: "input-group-text" }, [
            _createElementVNode("i", { class: "fas fa-comment-dots" })
          ], -1)),
          _withDirectives(_createElementVNode("textarea", {
            class: "form-control",
            id: "message",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.message) = $event)),
            placeholder: "Nachricht",
            rows: "5",
            required: ""
          }, null, 512), [
            [_vModelText, _ctx.formData.message]
          ])
        ]),
        _cache[7] || (_cache[7] = _createElementVNode("button", {
          type: "submit",
          class: "btn btn-primary d-block w-100"
        }, [
          _createElementVNode("i", { class: "fas fa-paper-plane" }),
          _createTextVNode(" Absenden ")
        ], -1))
      ], 32)
    ])
  ]))
}