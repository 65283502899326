<template>
  <div id="app">
    <!-- Navbar bleibt sichtbar -->
    <SiteNavbar/>

    <!-- Dynamischer Inhalt der Route (volle Breite) -->
    <router-view/>

    <!-- Footer bleibt sichtbar -->
    <SiteFooter/>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import SiteNavbar from './components/SiteNavbar.vue';
import SiteFooter from './components/SiteFooter.vue';

export default defineComponent({
  name: 'App',
  components: {
    SiteNavbar,
    SiteFooter,
  },
});
</script>

<style>

</style>
