import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/llm/llm.jpg'
import _imports_1 from '@/assets/llm/llm2.jpg'


const _hoisted_1 = { class: "llm-knowledge-management-page" }
const _hoisted_2 = { class: "section motivation-explanation-section py-5 dark-section" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = { class: "row align-items-center" }
const _hoisted_5 = { class: "col-md-6 text-center" }
const _hoisted_6 = { class: "section solution-section py-5 dark-section" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { class: "row align-items-center" }
const _hoisted_9 = { class: "col-md-6 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-in", { 'text-slide-in-active': _ctx.textVisible }])
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("h2", { class: "highlight-text highlight-text-margin" }, "LLM-basiertes Wissensmanagement", -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "lead-light mb-4" }, " Steigern Sie die Effizienz Ihres Wissensmanagements durch den Einsatz von LLMs wie ChatGPT, um Unternehmenswissen intelligent zu organisieren, zugänglich zu machen und nutzbar zu gestalten. Nutzen Sie KI, um Ihr Wissen optimal zu verwalten und eine effektive Entscheidungsfindung zu fördern. ", -1)),
            _createVNode(_component_router_link, {
              to: { name: 'LLM' },
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Kontaktieren Sie uns "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "Motivation für LLM-basiertes Wissensmanagement",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm top-image", { 'slide-in-active': _ctx.imageVisible }])
            }, null, 2)
          ])
        ])
      ])
    ]),
    _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"section solution-overview-section py-5 light-section\" data-v-68a74f3b><div class=\"container\" data-v-68a74f3b><div class=\"row text-center mb-5\" data-v-68a74f3b><div class=\"col-md-4\" data-v-68a74f3b><i class=\"fas fa-book fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-68a74f3b></i><h4 class=\"highlight-text-dark\" data-v-68a74f3b>KI-gestütztes Wissen organisieren</h4><p class=\"lead-dark\" data-v-68a74f3b> Organisieren Sie Ihr Wissen intelligent mit KI-Unterstützung und machen Sie es leicht zugänglich für alle Mitarbeiter. </p></div><div class=\"col-md-4\" data-v-68a74f3b><i class=\"fas fa-file-alt fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-68a74f3b></i><h4 class=\"highlight-text-dark\" data-v-68a74f3b>Automatisierte Berichterstellung</h4><p class=\"lead-dark\" data-v-68a74f3b> Erstellen Sie Berichte automatisiert und umfassend, um die Effizienz Ihrer internen Kommunikation zu steigern. </p></div><div class=\"col-md-4\" data-v-68a74f3b><i class=\"fas fa-headset fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-68a74f3b></i><h4 class=\"highlight-text-dark\" data-v-68a74f3b>Optimierte Kundenkommunikation</h4><p class=\"lead-dark\" data-v-68a74f3b> Verbessern Sie Ihre Kundenkommunikation proaktiv durch maßgeschneiderte Antworten und erhöhte Effizienz. </p></div></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Unsere LLM-basierte Wissensmanagement-Lösung",
              class: _normalizeClass(["img-fluid rounded-img shadow-sm solution-image", { 'slide-in-active': _ctx.solutionImageVisible }])
            }, null, 2)
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["col-md-6 mb-4 mb-md-0 text-slide-up", { 'text-slide-up-active': _ctx.solutionTextVisible }])
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("h2", { class: "highlight-text" }, "Unsere Lösung", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "lead-light mb-4" }, " Mit unseren maßgeschneiderten LLM-basierten Wissensmanagement-Lösungen können Sie Wissen effizient verwalten, komplexe Berichte erstellen und eine optimierte Kommunikation aufbauen – für ein dynamisches, zukunftsorientiertes Unternehmen. ", -1)),
            _createVNode(_component_router_link, {
              to: { name: 'LLM' },
              class: "btn btn-primary btn-lg mt-2"
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Jetzt Kontakt aufnehmen "),
                _createElementVNode("i", { class: "fas fa-envelope" }, null, -1)
              ])),
              _: 1
            })
          ], 2)
        ])
      ])
    ]),
    _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"section benefits-section py-5 light-section\" data-v-68a74f3b><div class=\"container\" data-v-68a74f3b><div class=\"row text-center\" data-v-68a74f3b><div class=\"col-md-12\" data-v-68a74f3b><h2 class=\"highlight-text-dark\" data-v-68a74f3b>Ihre Vorteile</h2><p class=\"lead-dark mb-4\" data-v-68a74f3b> Profitieren Sie von intelligentem Wissensmanagement durch LLMs und heben Sie Ihre Entscheidungsfindung und Effizienz auf das nächste Level. </p></div></div><div class=\"row text-center mt-4\" data-v-68a74f3b><div class=\"col-md-4\" data-v-68a74f3b><i class=\"fas fa-brain fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-68a74f3b></i><h4 class=\"highlight-text-dark\" data-v-68a74f3b>Intelligente Wissensorganisation</h4><p class=\"lead-dark\" data-v-68a74f3b>KI unterstützt die strukturierte Organisation Ihres Wissens, um eine fundierte Entscheidungsbasis zu schaffen.</p></div><div class=\"col-md-4\" data-v-68a74f3b><i class=\"fas fa-file-alt fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-68a74f3b></i><h4 class=\"highlight-text-dark\" data-v-68a74f3b>Umfassende Berichte</h4><p class=\"lead-dark\" data-v-68a74f3b>Erstellen Sie detaillierte Berichte schnell und effizient mit KI-Unterstützung für maximale Transparenz.</p></div><div class=\"col-md-4\" data-v-68a74f3b><i class=\"fas fa-headset fa-3x mb-3\" style=\"color:#e2a84b;\" data-v-68a74f3b></i><h4 class=\"highlight-text-dark\" data-v-68a74f3b>Optimierte Kommunikation</h4><p class=\"lead-dark\" data-v-68a74f3b>Verbessern Sie die Kundenkommunikation und bieten Sie maßgeschneiderte, proaktive Antworten.</p></div></div></div></div>", 1))
  ]))
}